import type { Transition, UIRouter } from '@uirouter/core';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { anotherComponentHookCriteria } from '../../helpers';

export function viewerNavigationPlugin(flowApi: ControllerFlowAPI) {
  return function (router: UIRouter) {
    router.transitionService.onBefore(
      { to: anotherComponentHookCriteria },
      handleNavigation,
      { invokeLimit: 1 },
    );

    return {
      name: 'WixUIRouterViewerNavigation',
    };
  };

  function handleNavigation(transition: Transition) {
    return transition
      .targetState()
      .withParams({ scrollToTop: true, ...transition.params() });
  }
}
