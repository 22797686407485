import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { ContentType } from '@wix/ambassador-feed-v1-feed-item/types';
import { EmptyState } from '@wix/ambassador-social-groups-v2-central-feed-item/types';
import { Role as GroupRole } from '@wix/ambassador-social-groups-v2-group-role/types';
import { getPricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';

import { ReactionMode } from 'api/feed/types';
import { IMember } from 'api/members/types';
import * as feedApi from 'api/feed';
import * as centralFeedApi from 'api/central-feed';

import * as topics from '../topics';
import { createAsyncThunk, serializeHttpError } from '../utils';
import { selectCurrentUser } from '../application/selectors';

import { selectors } from './adapter';
import { feedSlice } from './slice';

import {
  ICreateParams,
  IFetchCentralFeedParams,
  IFetchParams,
  IGetParams,
  IPinParams,
  IReactParams,
  IRemoveParams,
  ISubscribeParams,
  IUnpinParams,
  IUnreactParams,
  IUnsubscribeParams,
  IUpdateParams,
} from './types';
import { IWixAPI } from '@wix/yoshi-flow-editor';

export const create = createAsyncThunk(
  'feed:create',
  async function (params: ICreateParams, thunkAPI) {
    const { groupId } = params;
    const { authentication } = thunkAPI.extra.controllerConfig.wixCodeApi;

    const feedItem = await authentication.withCaptchaChallengeHandler(
      (captchaToken) =>
        thunkAPI.extra.httpClient
          .request(
            feedApi.create(groupId, {
              captchaToken,
              entity: {
                topics: params.topics,
                body: {
                  content: params.content,
                  contentType: ContentType.DRAFTJS,
                },
              },
            }),
          )
          .then((response) => response.data)
          .catch((error) => Promise.reject(serializeHttpError(error))),
    );

    if (feedItem.entity.topics.length) {
      thunkAPI.dispatch(
        topics.actions.incrementCounter(
          feedItem.entity.topics.map((topic) => topic.id as string),
        ),
      );
    }

    return feedItem;
  },
);

export const update = createAsyncThunk(
  'feed:update',
  async function (params: IUpdateParams, thunkAPI) {
    const { groupId, feedItemId } = params;
    const { authentication } = thunkAPI.extra.controllerConfig.wixCodeApi;

    const old = selectors.selectById(thunkAPI.getState(), feedItemId);

    const feedItem = await authentication.withCaptchaChallengeHandler(
      (captchaToken) =>
        thunkAPI.extra.httpClient
          .request(
            feedApi.update(groupId, {
              feedItemId,
              captchaToken,
              entity: {
                topics: params.topics,
                body: {
                  content: params.content,
                  contentType: ContentType.DRAFTJS,
                },
              },
            }),
          )
          .then((response) => response.data)
          .catch((error) => Promise.reject(serializeHttpError(error))),
    );

    const removedTopics = old!.entity.topics.filter(
      ({ id }) => !feedItem.entity.topics.some((topic) => topic.id === id),
    );

    const addedTopics = feedItem.entity.topics.filter(
      (topic) => !old?.entity.topics.some(({ id }) => topic.id === id),
    );

    thunkAPI.dispatch(
      topics.actions.decrementCounter(
        removedTopics.map((topic) => topic.id as string),
      ),
    );

    thunkAPI.dispatch(
      topics.actions.incrementCounter(
        addedTopics.map((topic) => topic.id as string),
      ),
    );

    return feedItem;
  },
);

export const subscribe = createAsyncThunk(
  'feed:subscribe',
  async function (params: ISubscribeParams, thunkAPI) {
    const { groupId, feedItemId } = params;

    return thunkAPI.extra.httpClient.request(
      feedApi.subscribe(groupId, feedItemId),
    );
  },
);

export const unsubscribe = createAsyncThunk(
  'feed:unsubscribe',
  async function (params: IUnsubscribeParams, thunkAPI) {
    const { groupId, feedItemId } = params;

    return thunkAPI.extra.httpClient.request(
      feedApi.unsubscribe(groupId, feedItemId),
    );
  },
);

export const pin = createAsyncThunk(
  'feed:pin',
  async function (params: IPinParams, thunkAPI) {
    const { groupId, feedItemId } = params;

    return thunkAPI.extra.httpClient.request(feedApi.pin(groupId, feedItemId));
  },
);

export const unpin = createAsyncThunk(
  'feed:unpin',
  async function (params: IUnpinParams, thunkAPI) {
    const { groupId, feedItemId } = params;

    return thunkAPI.extra.httpClient.request(
      feedApi.unpin(groupId, feedItemId),
    );
  },
);

export const react = createAsyncThunk(
  'feed:react',
  async function (params: IReactParams, thunkAPI) {
    const { groupId, code, feedItemId, mode } = params;

    const user = selectCurrentUser(thunkAPI.getState()) as IMember;

    try {
      thunkAPI.dispatch(
        feedSlice.actions.react({
          feedItemId,
          code,
          replace: mode === ReactionMode.REPLACE,
          user: {
            ...user,
            role: GroupRole.UNKNOWN_ROLE,
          },
        }),
      );

      return await thunkAPI.extra.httpClient.request(
        feedApi.react(groupId, feedItemId, code, mode),
      );
    } catch (error) {
      thunkAPI.dispatch(
        feedSlice.actions.unreact({
          feedItemId,
          code,
          user: {
            ...user,
            role: GroupRole.UNKNOWN_ROLE,
          },
        }),
      );

      return Promise.reject(error);
    }
  },
  {
    formatError: (params) => ({
      i18nKey: 'groups-web.toast.error.feed.react',
      values: {
        code: params.code,
      },
    }),
  },
);

export const unreact = createAsyncThunk(
  'feed:unreact',
  async function (params: IUnreactParams, thunkAPI) {
    const { groupId, code, feedItemId } = params;

    const user = selectCurrentUser(thunkAPI.getState()) as IMember;

    try {
      thunkAPI.dispatch(
        feedSlice.actions.unreact({
          feedItemId,
          code,
          user: {
            ...user,
            role: GroupRole.UNKNOWN_ROLE,
          },
        }),
      );

      return await thunkAPI.extra.httpClient.request(
        feedApi.unreact(groupId, feedItemId, code),
      );
    } catch (error) {
      thunkAPI.dispatch(
        feedSlice.actions.react({
          feedItemId,
          code,
          user: {
            ...user,
            role: GroupRole.UNKNOWN_ROLE,
          },
        }),
      );

      return Promise.reject(error);
    }
  },
  {
    formatError: (params) => ({
      i18nKey: 'groups-web.toast.error.feed.unreact',
      values: {
        code: params.code,
      },
    }),
  },
);

export const remove = createAsyncThunk(
  'feed:remove',
  async function (params: IRemoveParams, thunkAPI) {
    const { groupId, feedItemId } = params;

    const state = thunkAPI.getState();
    const feedItem = selectors.selectById(state, feedItemId);

    if (feedItem?.entity.topics.length) {
      thunkAPI.dispatch(
        topics.actions.decrementCounter(
          feedItem?.entity.topics.map((topic) => topic.id as string),
        ),
      );
    }

    return thunkAPI.extra.httpClient.request(
      feedApi.remove(groupId, feedItemId),
    );
  },
);

export const get = createAsyncThunk(
  'feed:get',
  async function (params: IGetParams, thunkAPI) {
    const { groupId, feedItemId } = params;

    const { data } = await thunkAPI.extra.httpClient.request(
      feedApi.get(groupId, feedItemId),
    );

    data.item = (
      await withTranslatedPricingPlansDetails([data.item], thunkAPI.extra)
    )[0];
    return data.item;
  },
);

export const fetch = createAsyncThunk(
  'feed:fetch',
  async function (params: IFetchParams, thunkAPI) {
    const { groupId, query, cursor } = params;

    const res = await thunkAPI.extra.httpClient.request(
      feedApi.fetch(groupId, {
        cursor,
        query,
      }),
    );

    res.data.items = await withTranslatedPricingPlansDetails(
      res.data.items,
      thunkAPI.extra,
    );
    return res;
  },
);

export const fetchCentralFeed = createAsyncThunk(
  'feed:central-feed:fetch',
  async function (params: IFetchCentralFeedParams, thunkAPI) {
    const { httpClient, experiments } = thunkAPI.extra;

    const res = await httpClient.request(
      centralFeedApi.fetch({
        emptyState: experiments.enabled('specs.groups.EnablePublicFeed')
          ? EmptyState.PUBLIC_POSTS
          : EmptyState.EMPTY,
        limit: params.cursor?.limit!,
        cursor: params.cursor?.cursor,
      }),
    );

    res.data.items = await withTranslatedPricingPlansDetails(
      res.data.items,
      thunkAPI.extra,
    );
    return res;
  },
  {
    formatError: () => ({ i18nKey: 'groups-web.toast.error.feed.fetch' }),
  },
);

async function withTranslatedPricingPlansDetails(
  feedItems: feedApi.IFeedItem[],
  flowAPI: ControllerFlowAPI,
) {
  try {
    const api = await getPricingPlansPublicAPI(
      flowAPI.controllerConfig.wixCodeApi,
    );
    return feedItems.map((feedItem) => {
      if (feedItem.accessRestriction?.paidPlansOptions?.plans) {
        feedItem.accessRestriction.paidPlansOptions.plans =
          feedItem.accessRestriction?.paidPlansOptions?.plans.map((plan) => {
            return {
              ...plan,
              translatedDetails: api.getTranslatedPricingDetails(plan),
            };
          });
      }

      return feedItem;
    });
  } catch (e: any) {
    flowAPI.reportError(e);
    return feedItems;
  }
}
